import React from "react"
import "../assets/styles/page/notfound.scss"
import Character from "../assets/images/Character.png"
import Layout from "../components/layout"

const NotFound = () => {
  return (
    <div className="notfound--page--cointainer">
      <Layout>
        <div className="notfound--content--wrapper">
          <div className="notfound--text--wrapper">
            <p className="notfound--headtext">OOPS</p>
            <p className="notfound--subtext">We can’t seem to find the page you’re looking for.</p>
            <h3 className="notfound--error">Error code: 404</h3>
          </div>
          <div className="notfound--image--container">
            <div className="notfound--image">
              <img src={Character} alt="404CHARACTER" />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default NotFound
